import { FC } from "react";
import "./Footer.css";

const Footer: FC = () => {
  return (
    <footer>
      Brandmessage.ai is a website owned and operated by Get Clear Consulting
      LTD. Get Clear Consulting uses a variety of tools to help our clients grow
      their business with improved marketing.
      <br></br>
      <br></br>
      "Robot Playground" (https://skfb.ly/6QXFq) by Hadrien59 is licensed under
      Creative Commons Attribution
      (http://creativecommons.org/licenses/by/4.0/).
    </footer>
  );
};

export default Footer;
