import { FC, FormEventHandler, useState } from "react";
import "./ContactForm.css";

export interface ContactFormProps {
  handleSubmit: (params: { firstName: string; email: string }) => Promise<void>;
}

const ContactForm: FC<ContactFormProps> = ({ handleSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    await handleSubmit({ firstName, email });
  };

  return (
    <form className="form contact-form" onSubmit={onSubmit}>
      <h2 className="heading">
        Would you like to receive an email showing you how to best apply a brand
        message to your business?
      </h2>
      <div className="contact-form-inputs">
        <label className="contact-form-label" htmlFor="first-name">
          First Name:
          <input
            name="first-name"
            maxLength={150}
            type="text"
            className="input"
            value={firstName}
            required
            onChange={(e) => setFirstName(e.target.value)}
          />
        </label>
        <label className="contact-form-label" htmlFor="email">
          Email:
          <input
            name="email"
            maxLength={300}
            type="email"
            className="input"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <button type="submit" className="btn action-btn">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
