import { FC } from "react";
import "./Loader.css";

const Loader: FC = () => (
  <div className="loader-container">
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p className="message">Please wait while we generate your new brand message.</p>
  </div>
);

export default Loader;
