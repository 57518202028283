import { FC, useEffect, useState } from "react";
import GetClearLogo from "./get-clear-logo.png";
import Footer from "./Footer";
import VideoDialog from "./VideoDialog";
import WhoMadeThis from "./WhoMadeThis";
import Wireframe, { WebsiteData } from "./Wireframe";
import Loader from "./Loader";
import { useParams } from "react-router-dom";
import contentToScript from "./content-to-script";
import { ScriptType } from "./Script";
import ScriptSection from "./ScriptSection";
import "./Brandscript.css";

const Brandscript: FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<{
    story?: string[];
    pitch: string;
    headlines: string[];
    website: WebsiteData;
  }>({
    pitch: "",
    headlines: [],
    website: {
      cta: "",
      h1: "",
      h2: "",
      h3: "",
      h4: "",
      p1: "",
      p2: "",
      benefitsHeading: "",
      benefits: [],
      problemHeading: "",
      problemParagraphs: [],
      stepsSummary: "",
      steps: [],
    },
  });

  const [script, setScript] = useState<ScriptType[]>([]);
  const [story, setStory] = useState<string[]>([]);
  const [showScript, setShowScript] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);

  const fetchData = async ({ shouldRewrite } = { shouldRewrite: false }) => {
    setLoading(true);

    const params = new URLSearchParams(window.location.search);
    const secret = params.get("secret");

    try {
      const res = await fetch(
        `https://us-west1-brandscript-generator-390322.cloudfunctions.net/brandscript-generator?id=${id}&shouldRewrite=${shouldRewrite}${
          secret ? `&secret=${secret}` : ""
        }`
      );

      if (res.status !== 200) {
        alert("Sorry something went wrong. Please try again later.");
        return;
      }

      const json = await res.json();

      const originalResponse = JSON.parse(json.originalResponse);
      setScript(contentToScript(originalResponse.script));
      setStory(originalResponse.story);

      const content = JSON.parse(json.data.choices[0].message.content);
      setContent(content);

      setShowScript(true);
    } catch (e) {
      console.error(e);
      alert("Sorry something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <main className="brandscript-main">
      <a
        className="get-clear-link"
        href="https://getclear.ca"
        target="_blank"
        rel="noreferrer"
      >
        <img className="logo" src={GetClearLogo} alt="Get Clear" />
      </a>

      <ScriptSection script={script} story={story} showScript={showScript} />

      {/*!closeDialog && (
        <VideoDialog
          title="How To Build A Home Page"
          src="https://player.vimeo.com/video/853174453?h=b8c0f8b0a9&title=0&byline=0&portrait=0"
          setCloseDialog={setCloseDialog}
        />
        )*/}

      {loading && <Loader />}

      {showScript && (
        <section className="website-ideas-section container">
          {!loading && (
            <>
              <details>
                <summary className="marketing-heading">Your Pitch</summary>
                <div>
                  <p className="script-content">{content.pitch}</p>
                </div>
              </details>

              <details>
                <summary className="marketing-heading">Headline Ideas</summary>
                <div>
                  <ul className="script-content">
                    {content.headlines?.map((h, i) => (
                      <li key={i}>{h}</li>
                    ))}
                  </ul>
                </div>
              </details>

              <button
                className="brandscript-btn btn action-btn"
                type="button"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure? You’ll lose what we created so far... but who knows? It might be better."
                    )
                  ) {
                    await fetchData({ shouldRewrite: true });
                  }
                }}
              >
                Try Again
              </button>

              <h2 className="marketing-heading">
                Here&apos;re A Few Ideas For Your Website
              </h2>

              <Wireframe
                website={content.website}
                stepsHeadings={script[3].elements[0].list ?? []}
              />

              <button
                className="brandscript-btn btn action-btn"
                type="button"
                onClick={window.print}
              >
                Save as PDF
              </button>
            </>
          )}
        </section>
      )}

      {!loading && <WhoMadeThis />}

      <Footer />
    </main>
  );
};

export default Brandscript;
