import { ScriptType } from "./Script";

const contentToScript = (script: string[] | string[][]): ScriptType[] => {
  return [
    {
      title: "Your Ideal Customer",
      elements: [
        { content: script[0] as string },
        // { content: script[1] as string },
      ],
    },
    {
      title: "Has a Problem",
      elements: [
        { title: "The Problem", content: script[2] as string },
        { title: "The Emotion Connected To It", content: script[3] as string },
        { title: "Why It Matters", content: script[4] as string },
      ],
    },
    {
      title: "Your Role In The Story",
      elements: [
        { content: script[5] as string },
        { content: script[6] as string },
      ],
    },
    {
      title: "How You Help",
      elements: [
        {
          content:
            "Customers trust you because you give them a plan",
          list: script[7] as string[],
        },
      ],
    },
    {
      title: "Your Clear Call To Action",
      elements: [{ content: script[8] as string }],
    },
    {
      title: "What Happens If They Don't",
      elements: [{ content: script[9] as string }],
    },
    {
      title: "The Ideal Outcome",
      elements: [{ content: script[10] as string }],
    },
    // {
    //   title: "Here’s Your Villain",
    //   elements: [{ content: script[11] as string }],
    // },
  ];
};

export default contentToScript;
